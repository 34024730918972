.homeSection {
    margin-bottom: 5vh;
}

.homeSummary {
    margin-top: 5vh;
}


.homeSectionName {
    position: absolute;
    margin: auto;
    text-align: center;
    width: 100%;
    top: 3vh;
    font-size: 3ch;
    left: 1.5ch;
    z-index: 5;
    font-weight: bolder;
    line-height: 2ch;
}

.homeSectionTitle {
    width: 100%;
    text-align: center;
    font-family: 'Montserrat';
    font-size: 32px;
    margin: 0;
}

.homecard {
    width: 60%;
    margin: auto !important;
    text-align: center;
    border: none !important;
    padding: 5% !important;
    border-radius: 10% !important;
    margin-bottom: 30px !important;
}

.portfolioCard .ant-card-cover {
    background-color: #fff5ec;
}